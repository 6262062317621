import React from "react";
import Preloader from "../helper/Preloader";
import ColorInit from "../helper/ColorInit";

import Breadcrumb from "../components/Breadcrumb";

import BottomFooter from "../components/BottomFooter";
// import ShippingOne from "../components/ShippingOne";
import Checkout from "../components/Checkout";
import ScrollToTop from "react-scroll-to-top";
import HeaderOne from "../components/HeaderOne";
import FooterOne from "../components/FooterOne";
import Footertop from "../components/Footertop";
// import { AddressProvider } from "../context/AddressContext";

const CheckoutPage = () => {
  return (
    <>
      {/* ColorInit */}
      <ColorInit color={false} />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#299E60" />

      {/* Preloader */}
      <Preloader />

      {/* HeaderTwo */}
      <HeaderOne category={true} />

      {/* Breadcrumb */}
      <Breadcrumb title={"Checkout"} />

      {/* Checkout */}
      <Checkout />

      {/* ShippingOne */}
      {/* <ShippingOne /> */}

      {/* FooterTwo */}
      <Footertop />

      <FooterOne />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default CheckoutPage;
