import React from "react";
import { Link } from "react-router-dom";

export default function ShopCard({ item }) {
  return (
    <div className="col-lg-2  col-md-3 col-sm-6 col-6 mb-24 px-3">
      <Link to={`/product-details/${item.id}`} className="">
        <div className="product-car  pb-16 border-none rounded-16 position-relative transition-2 bg-white">
          <Link
            to="/"
            className="product-card__cart btn bg-white text-main-600 hover-bg-main-600 hover-text-white py-11 px-21 rounded-pill flex-align gap-8 position-absolute inset-block-end-0 inset-inline-start-0 ms-16 mb-110"
          >
            <i className="ph ph-heart" />
          </Link>

          <Link
            to={`/product-details/${item.id}`}
            className="product-card__thub flex-center"
          >
            <img
              src={`${item?.image_url}` || "assets/images/bg/testfish.png"}
              alt="prod"
              style={{
                width: "180px",
                height: "auto",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              }}
            />
          </Link>
          <div className="product-card__content mt-12 px-8">
            {/* <div className="product-card__price mb-16">
              <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                {item?.offer_price}
              </span>
              <span className="text-heading text-md fw-semibold">
                {item?.price}{" "}
                <span className="text-gray-500 fw-normal">/499</span>
              </span>
            </div> */}
            <h6 className="title text-lg fw-semibold mt-12 mb-8">
              <Link
                to="/product-details"
                className="link text-line-2 "
                style={{ fontSize: "13px" }}
              >
                {item?.name}
              </Link>
            </h6>
            <div className="flex-align gap-4"></div>
          </div>
        </div>
      </Link>
    </div>
  );
}
