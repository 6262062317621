import React from "react";

const ErrorList = ({ errors }) => {
  if (!errors || errors.length === 0) return null; // If there are no errors, return nothing.

  return (
    <div className="error-list" style={{ color: "red", marginBottom: "1rem" }}>
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorList;
