import React, { useEffect, useRef, useState } from "react";
import { NewAddress } from "./NewAddress";
import {
  // addNewAddress,
  deleteAddress,
  getUserAddress,
  // updateUserAddress,
} from "../api/addressApi";

const AddressBook = () => {
  const addressRef = useRef([]); // Store addresses
  const [renderTrigger, setRenderTrigger] = useState(false); // Trigger re-render
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [address, setaddress] = useState(null);
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const res = await getUserAddress();
        addressRef.current = res?.data?.result?.data?.address_list || [];
        console.log(addressRef.current, res, "Fetched Addresses");
        setRenderTrigger(true);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, [renderTrigger]);
  const addr = addressRef.current.find((addr) => addr.type === "delivery");

  const handleUpdate = (e, address) => {
    e.preventDefault();
    setIsFormOpen(!isFormOpen);
    setaddress(address);
  };
  const handleDelete = (e, address_id) => {
    e.preventDefault();
    // setIsFormOpen(!isFormOpen);
    // setaddress(address);
    deleteAddress(address_id);
  };

  return (
    <>
      {addressRef.current.length > 0 ? (
        <div>
          <div>
            <div className="d-flex w-100 justify-content-between">
              <p className="font-weight-bold">Delivery Address</p>
              <p className="font-weight-bold ">Edit</p>
            </div>
            <hr />
            <div style={{ marginBottom: "1rem" }} className="col-12 ">
              <p>
                <strong>Address:</strong> {addr.address1}, {addr.address2}
              </p>
              <p>
                <strong>City:</strong> {addr.city}
              </p>
              <p>
                <strong>Postcode:</strong> {addr.postcode}
              </p>
              <p>
                <strong>State:</strong> {addr.state}
              </p>
              <p>
                <strong>Country:</strong> {addr.country}
              </p>
              <p>
                <strong>Email:</strong> {addr.email}
              </p>
              <p>
                <strong>Phone:</strong> {addr.address2}
              </p>
              <p>
                <strong>Landmark:</strong> {addr.landmark}
              </p>
              <p>
                <strong>Type:</strong> {addr.type}
              </p>
            </div>
            {/* <hr /> */}
            <button
              className="btn btn-main mt-40 py-18 w-100 rounded-8"
              onClick={(e) => {
                e.preventDefault();
                setIsFormOpen(!isFormOpen);
                setaddress(null);
              }}
            >
              Add New Address
            </button>
            <hr />
          </div>
          {/* <NewAddress /> */}
          {isFormOpen ? <NewAddress address={address} /> : ""}

          <div className="d-flex flex-wrap">
            {/* {addressRef.current.length} */}
            {addressRef.current.map((addr, index) => (
              // <Address addr={addr} key={addr.address_id} />
              <div
                style={{ marginBottom: "1rem" }}
                className="col-6 "
                key={addr?.id}
              >
                <p>
                  <strong>Address:</strong> {addr.address1}, {addr.address2}
                </p>
                <p>
                  <strong>City:</strong> {addr.city}
                </p>
                <p>
                  <strong>Postcode:</strong> {addr.postcode}
                </p>
                <p>
                  <strong>State:</strong> {addr.state}
                </p>
                <p>
                  <strong>Country:</strong> {addr.country}
                </p>
                <p>
                  <strong>Email:</strong> {addr.email}
                </p>
                <p>
                  <strong>Phone:</strong> {addr.address2}
                </p>
                <p>
                  <strong>Landmark:</strong> {addr.landmark}
                </p>
                <p>
                  <strong>Type:</strong> {addr.type}
                </p>
                <div className="mt-20 ">
                  {" "}
                  <button
                    className="me-20"
                    onClick={(e) => handleUpdate(e, addr)}
                  >
                    Update
                  </button>
                  <button onClick={(e) => handleDelete(e, addr.address_id)}>
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NewAddress />
      )}
    </>
  );
};

export default AddressBook;
