import React from "react";

export default function Footertop() {
  return (
    <div className="mt-40">
      <section
        className="  pt16 "
        style={{ backgroundColor: "rgb(240, 250, 255)" }}
      >
        <div className="container container-lg py-32 pt-40">
          <h5 style={{ textAlign: " left" }}>We are Present in</h5>
        </div>
        <div className=" d-flex flex-wrap container gap-20 pb-32">
          {[
            "Pannithadam",
            "Valanchery",
            "Pattambi",
            "KottakKal",
            "Guruvayur",
          ].map((el, i) => (
            <div
              className="col-lg-2 bg-white px-12  rounded-pill pt-3 "
              key={i}
              style={{ height: "35px", marginRight: "10px" }}
            >
              <p style={{ textAlign: "center", fontSize: " 14px" }}>
                {el}
                <br />
              </p>
            </div>
          ))}
        </div>
      </section>
      <section className="  " style={{ backgroundColor: "rgb(240, 250, 255)" }}>
        <div
          className=" container container-lg"
          style={{ backgroundColor: "rgb(240, 250, 255)" }}
        >
          <div
            className="row pt-10 "
            style={{ backgroundColor: "rgb(240, 250, 255)" }}
          ></div>
        </div>
      </section>

      {/* <div class="container container-lg">
        </div>
    </section> */}
      <section className=" pb16 pt40 bg-whitecontainer container-lg ">
        <div className=" pt-32">
          <h5>
            <p className="" style={{ fontWeight: "700", fontSize: "24px" }}>
              Our Products
            </p>
          </h5>
        </div>
        <div className="container container-lg">
          <h6 style={{ textAlign: " left" }}>
            <span style={{ fontSize: "18px" }}>Marine Fish</span>
          </h6>
        </div>

        <div className=" container container-lg">
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "14px" }}>
              Anchovy / Kozhuva / Natholi | Barracuda / Cheelavu | Pomfret /
              Avoli | Threadfin Bream / Kilimeen / Goat Fish | Butter Fish /
              Punnarameen
            </span>
            <span style={{ fontSize: "14px" }}>
              Cobia / Motha | Emperor / Eari | Garfish / Kola | Grey Mullet /
              Thirutha | Grouper / Reef Cods / Kalava | Mackerel / Ayala
            </span>
            <span style={{ fontSize: "14px" }}>
              Stingray / Thirandi | Kallanki | Salmon | Lady Fish / Silver
              whiting / Kane | Silver Biddy / Pranjil | Long Whiskered / Catfish
              | Mahi Mahi
            </span>
            <span style={{ fontSize: "14px" }}>
              Milk Fish / Poomeen | Pony Fish / Mullan | Red Snapper / Chempalli
              / Rane | Reef Cods / Kalava | Ribbon Fish / Vaala
            </span>
            <span style={{ fontSize: "14px" }}>
              Sail Fish / Ola Meen | Eel / Mananjil | Queen Fish | Sardine /
              Mathy / Mathi | Seer Fish / Neymeen / Surumai | Shark
            </span>
            <span style={{ fontSize: "14px" }}>
              Shrimp Scad / Vatta Paara | Pomfret / Avoli / Pabda | Snapper |
              Sole Fish / Manthal / Repti | Sword Fish | Trevally / Vatta | Tuna
            </span>
            <span style={{ fontSize: "14px" }}>
              White Sardine / Veloori | Jobfish | Silver Belly / Thaali Mullen
            </span>

            <br />
          </p>
        </div>

        <div className="container container-lg">
          <h6 style={{ textAlign: " left" }}>
            <span style={{ fontSize: "18px" }}>Freshwater Fish</span>
          </h6>
        </div>

        <div className=" container container-lg">
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "14px" }}>
              Baasa / Pangasius | Catla / Katla | Freshwater Milk Fish / Kayal
              Poomeen |Goby / Poolan / Bele Mach | Mrigal / Kanni Meen
            </span>
            <span style={{ fontSize: "14px" }}>
              Mullet / Kanambu / Parshe Maach | Pearl Spot / Karimeen / Koral |
              Rohu | Tilapia | Catfish / Manjakoori / Tengra Mach
            </span>
            <span style={{ fontSize: "14px" }}>
              Barramundi / Bhetki / Asian Seabass
            </span>
            <br />

            <br />
          </p>
        </div>

        <div className="container container-lg">
          <h6 style={{ textAlign: " left" }}>
            <span style={{ fontSize: "18px" }}>Shell Fish</span>
          </h6>
        </div>
        <div className="container  container-lg">
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "14px" }}>
              Shrimp | Clam / Kakka | Cuttle Fish| Green Mussel Meat /
              Kallummekkaya | Lobster | Prawns / Venami | Octopus | Oyster Meat
            </span>
            <span style={{ fontSize: "14px" }}>
              Sand Lobster | Scampi / Attukonchu / Jinga / Chingdi | Crab | Mud
              Crab | Squid / Koonthal | Tiger prawn / Bagda / Chingri
            </span>

            <br />
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <div
            className=" container container-lg center "
            style={{
              margin: "50px",
              borderRadius: "15px",
              paddingTop: "50px",
              paddingBottom: "50px",
              backgroundColor: "rgb(244, 250, 255)",
            }}
          >
            <div
              className="row"
              style={{ marginLeft: "-20px", marginRight: "-20px" }}
            >
              <div className="col-lg-6 pt24">
                <p
                  className=""
                  style={{
                    fontWeight: "600px",
                    fontSize: "32px",
                    marginBottom: "10px",
                    color: "var(--secondary)",
                  }}
                >
                  Didn't find what you're <br /> looking for ?
                </p>
                <p
                  style={{
                    backgroundColor: "rgb(240, 250, 255)",
                    textAlign: "left",
                  }}
                >
                  <a href="/product-request">
                    {/* <img src="" alt="" class="me-2 img img-fluid o_we_custom_image" data-original-id="2658" data-original-src="/web/image/2658-753f25d8/Requests%20button.png" data-mimetype="image/png" data-resize-width="210" data-bs-original-title="" title="" aria-describedby="tooltip428824" loading="lazy" /> */}
                    <button
                      className="btn btn-"
                      style={{ background: "var(--secondary)" }}
                    >
                      Request Product
                    </button>
                  </a>
                </p>
              </div>
              <div
                className="o_colored_level col-lg-6 pt0"
                style={{ backgroundColor: "rgb(240, 250, 255)" }}
              >
                <p style={{ textAlign: " right" }}>
                  <img
                    src="../assets/images/shape/productReq.svg"
                    alt=""
                    className="me-2 mt-3"
                  />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="s_allow_columns container">
          <h6>
            <strong>
              <span style={{ textAlign: " left" }} />
            </strong>
            <br />
          </h6>
        </div>
      </section>
    </div>
  );
}
