import React, { useState } from "react";
import { addNewAddress, updateAddress } from "../api/addressApi";
import { validateAddress } from "../utils/validations";
// import AddressInput from "./AddressInput";
import ErrorList from "./ErrorList";

export const NewAddress = ({ address: addr }) => {
  const [address, setAddress] = useState(() =>
    addr
      ? {
          id: addr.address_id,
          name: addr?.name,
          street: addr?.address1,
          phone1: addr?.address2,
          zip: addr?.postcode,
          city: addr?.city,
          email: addr?.email,
          landmark: addr?.landmark,
        }
      : {
          name: "",
          street: "",
          city: "",
          zip: "",
          email: "",
          phone1: "",
          landmark: "",
        }
  );
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const validationErrors = validateAddress(address);
    if (validationErrors.length) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    setErrors([]);

    try {
      if (addr) {
        // Update address if `addr` exists
        await updateAddress(address);
        console.log("Address updated successfully");
      } else {
        // Add a new address if `addr` is not provided
        await addNewAddress(address);
        console.log("Address added successfully");
      }
    } catch (error) {
      setErrors([error.message]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="my-20">
      <ErrorList errors={errors} />
      <form className="pe-xl-5" onSubmit={handleSubmit}>
        {/* <AddressInput
          type="text"
          name="name"
          value={address.name}
          placeholder="Name"
          onChange={handleChange}
        />
        Add other fields using AddressInput */}
        <div className="row gy-3">
          <div className="col-sm-12 col-xs-12">
            <input
              type="text"
              name="name"
              value={address.name}
              onChange={handleChange}
              className="common-input border-gray-100"
              placeholder="Name"
            />
          </div>
          <div className="col-12">
            <input
              type="text"
              name="city"
              value={address.city}
              onChange={handleChange}
              className="common-input border-gray-100"
              placeholder="City"
            />
          </div>
          <div className="col-12">
            <input
              type="text"
              name="street"
              value={address.street}
              onChange={handleChange}
              className="common-input border-gray-100"
              placeholder="Street Name"
            />
          </div>
          <div className="col-12">
            <input
              type="text"
              name="house"
              value={address.house}
              onChange={handleChange}
              className="common-input border-gray-100"
              placeholder="House Number"
            />
          </div>
          <div className="col-12">
            <input
              type="text"
              name="landmark"
              value={address.landmark}
              onChange={handleChange}
              className="common-input border-gray-100"
              placeholder="Landmark"
            />
          </div>
          <div className="col-12">
            <input
              type="number"
              name="zip"
              value={address.zip}
              onChange={handleChange}
              className="common-input border-gray-100"
              placeholder="Post Code"
            />
          </div>
          <div className="col-12">
            <input
              type="number"
              name="phone1"
              value={address.phone1}
              onChange={handleChange}
              className="common-input border-gray-100"
              placeholder="Phone"
            />
          </div>

          <div className="col-12">
            <input
              type="email"
              name="email"
              value={address.email}
              onChange={handleChange}
              className="common-input border-gray-100"
              placeholder="Email Address"
            />
          </div>
          <button
            className="btn btn-primary bg-main-700"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading
              ? "Processing..."
              : addr
              ? "Update Address"
              : "Add Address"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewAddress;
