import React from 'react'

export default function Testimonial() {
    return (
       
   <>
            <div className=" d-lg-flex d-none gap-30 my-40 justify-content-between contain container-lg" >
              <img src="assets/images/shape/testimony1.png" className="d-blok " alt="Slide 2" style={{width:"33%"}} />
              <img src="assets/images/shape/testimony1.png" className="d-bock " alt="Slide 3" style={{width:"33%"}} />
              <img src="assets/images/shape/testimony1.png" className="d-bloc " alt="Slide 1" style={{width:"33%"}} />
            </div>
             <div className=" d-lg-none d-flex flex-column container-lg mt-40 gap-20" >
             <img src="assets/images/shape/testimony1.png" className="d-block " alt="Slide 2" style={{width:"100%"}} />
             <img src="assets/images/shape/testimony1.png" className="d-block " alt="Slide 3" style={{width:"100%"}} />
             <img src="assets/images/shape/testimony1.png" className="d-block " alt="Slide 1" style={{width:"100%"}} />
           </div>
           </>
      );
}
