import React from 'react'
// import { Link } from 'react-router-dom'

export default function Taglines() {
    return (
        <section className="blog ">
            <div className="container container-lg  ">
                <div className='row d-none d-lg-flex my-48'>
                    <div className='col-lg-4  d-flex'>
                        <img src='assets/images/shape/van.svg' alt='' />
                         <p className='ml-3 text-' style={{color:"#004781",marginLeft:"10px" ,fontWeight:400}}>
                        Same day Delivery. 
                    Find your earliest delivery slot, Here </p></div>
                    <div className='col-lg-4 d-flex'><img src='assets/images/shape/chemicalfree.svg' alt=''/> <p style={{color:"#004781",fontWeight:400,marginLeft:"10px"}}>Enjoy pure, chemical-free goodness with our products. Taste nature's best!</p></div>
                    <div className='col-lg-4 d-flex'><img src='assets/images/shape/freshness.svg' alt=''/> <p style={{color:"#004781",fontWeight:400,marginLeft:"10px"}}>freshness in every bite with our unfrozen food products.</p> </div>
                </div>

                <div className='row d-lg-none d-flex gap-10 my-8'>
                    <div className='col-lg-4  d-flex '><img src='assets/images/shape/van.svg' alt='' className='p-3'/> <p className='ml-5 text-' style={{color:"#004781" ,fontWeight:400}}>
                        Same day Delivery. 
                    Find your earliest delivery slot, Here </p></div>
                    <div className='col-lg-4 d-flex'><img src='assets/images/shape/chemicalfree.svg' alt='' className='p-8'/> <p style={{color:"#004781",fontWeight:400}}>Enjoy pure, chemical-free goodness with our products. Taste nature's best!</p></div>
                    <div className='col-lg-4 d-flex'><img src='assets/images/shape/freshness.svg' alt=''  className='p-3'/> <p style={{color:"#004781",fontWeight:400}}>freshness in every bite with our unfrozen food products.</p> </div>
                </div>
                
                </div>
                </section>)}
