import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Persist cart to localStorage whenever it changes
  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const addToCart = (item, num) => {
    let isItemExists = cart.some((el) => el?.item?.id === item?.id);
    if (!isItemExists) {
      setCart((prevCart) => [...prevCart, { item, num }]);
    }
  };

  const removeFromCart = (item) => {
    const newcart = cart?.filter((e) => e.item.id !== item.item.id);
    setCart(newcart);
    localStorage.setItem("cart", JSON.stringify(newcart));
  };

  const increment = (item) => {
    let updatedCart = cart.map((el) => {
      if (el.item.id === item.item.id) {
        return { ...el, num: el.num + 1 };
      }
      return el;
    });
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart)); // Persist change
  };

  const decrement = (item) => {
    let updatedCart = cart.map((el) => {
      if (el.item.id === item.item.id) {
        return { ...el, num: el.num > 1 ? el.num - 1 : el.num };
      }
      return el;
    });
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, increment, decrement, removeFromCart }}
    >
      {children}
    </CartContext.Provider>
  );
};
