import React, { createContext, useState, useEffect } from "react";

export const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);

  // const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const storedMainCategory = localStorage.getItem("selectedMainCategory");
    if (storedMainCategory) {
      setSelectedMainCategory(JSON.parse(storedMainCategory));
    }
  }, []);

  useEffect(() => {
    if (selectedMainCategory) {
      localStorage.setItem(
        "selectedMainCategory",
        JSON.stringify(selectedMainCategory)
      );
    }
  }, [selectedMainCategory]);

  const changeMainCategory = (Category) => {
    setSelectedMainCategory(Category);
  };

  useEffect(() => {
    const storedCategory = localStorage.getItem("selectedSubCategory");
    if (storedCategory) {
      setSelectedSubCategory(JSON.parse(storedCategory));
    }
  }, []);

  useEffect(() => {
    if (selectedSubCategory) {
      localStorage.setItem(
        "selectedSubCategory",
        JSON.stringify(selectedSubCategory)
      );
    }
  }, [selectedSubCategory]);

  const changeSubCategory = (Category) => {
    setSelectedSubCategory(Category);
  };

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  return (
    <CategoryContext.Provider
      value={{
        selectedMainCategory,
        changeMainCategory,
        selectedSubCategory,
        changeSubCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
