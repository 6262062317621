import React from "react";
import { Link } from "react-router-dom";
import "./FooterOne.css";

const FooterOne = () => {
  return (
    <>
      {/* Desktop Footer */}
      <div style={{ backgroundColor: "var(--secondary)" }}>
        <footer
          className="footer p desktop-footer objectfit-cover pt-48"
          style={{ height: "540px", bottom: 0 }}
        >
          <img
            src="../assets/images/bg/footerbg.png"
            alt="footer"
            style={{
              backgroundColor: "var(--secondary)",
              height: "453px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "absolute",
              bottom: 0,
              width: "100vw",
              zIndex: "2",
            }}
          />
          {/* <div style={{ backgroundImage:"url('assets/images/bg/footerbg.png') " ,
                 backgroundColor:"var(--secondary)" ,backgroundPosition:"center bottom", backgroundRepeat: "no-repeat" , 
                 backgroundSize: "cover", width:'100%' ,height:"700px",bottom:0 }}> */}
          <div className="container container-lg ">
            <div className="footer-item-wrapper d-flex align-items-start justify-content-between mx-120 flex-wrap">
              <div className="footer-item" style={{ zIndex: "999" }}>
                <div className="footer-item__logo">
                  <Link to="/">
                    <img src="../assets/images/logo/logowhite.svg" alt="kifi" />
                  </Link>
                </div>
                <p className="mb-24 text-white">
                  Promoted by Helofish Supply Chain LLP
                </p>
                <div className="flex-align gap-16 mb-16">
                  {/* <span className="w-32 h-32 flex-center rounded-circle bg-main-600 text-white text-md flex-shrink-0">
                                    <i className="ph-fill ph-map-pin" />
                                </span> */}
                  <p className="text-md t text-white">
                    Corporate office: Door No: 15/809H, <br />
                    Panchami Complex, Perumpilavu,Karikkad P.O., <br />
                    680519,Thrissur
                  </p>
                </div>
                <div className="flex-align gap-16 mb-16"></div>

                <ul className="flex-align gap-16">
                  <li>
                    <a
                      href="https://www.facebook.com/kifionline/"
                      className="w-44 h-44 flex-center  hover-text-white"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* <i className="ph-fill ph-facebook-logo" /> */}
                      <img
                        src="../assets/images/socials/fb.svg"
                        alt="facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/kifionline/"
                      target="_blank"
                      rel="noreferrer"
                      className="w-44 h-44 flex-center  hover-text-white"
                    >
                      {/* <i className="ph-fill ph-facebook-logo" /> */}
                      <img src="../assets/images/socials/x.svg" alt="twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/kifionline/?igsh=MWt4c3hhdXR6N2k%3D#"
                      target="_blank"
                      rel="noreferrer"
                      className="w-44 h-44 flex-center  hover-text-white"
                    >
                      {/* <i className="ph-fill ph-facebook-logo" /> */}
                      <img
                        src="../assets/images/socials/insta.svg"
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href=" https://youtube.com/@kifionline?si=uYZeJTKhjPfZYLPh"
                      target="_blank"
                      rel="noreferrer"
                      className="w-44 h-44 flex-center  hover-text-white"
                    >
                      {/* <i className="ph-fill ph-facebook-logo" /> */}
                      <img
                        src="../assets/images/socials/Yt.svg"
                        alt="youtube"
                      />
                    </a>
                  </li>
                </ul>
                <div className="mt-60 pl-60">
                  <img
                    src="../assets/images/shape/fssai.png"
                    alt="kifi"
                    className="ml-60 w-100"
                  />
                </div>
              </div>

              <div className="footer-item" style={{ zIndex: "999" }}>
                <h6 className="footer-item__title" style={{ color: "#FFD0B5" }}>
                  Useful Links
                </h6>
                <ul className="footer-menu">
                  <li className="mb-16">
                    <Link to="/shop" className="text-white hover-text-main-600">
                      Home
                    </Link>
                  </li>
                  <li className="mb-16">
                    <Link to="/shop" className="text-white hover-text-main-600">
                      About us
                    </Link>
                  </li>
                  <li className="mb-16">
                    <Link to="/shop" className="text-white hover-text-main-600">
                      Products
                    </Link>
                  </li>
                  <li className="mb-16">
                    <Link to="/shop" className="text-white hover-text-main-600">
                      Services
                    </Link>
                  </li>
                  <li className="mb-16">
                    <Link to="/shop" className="text-white hover-text-main-600">
                      Legal
                    </Link>
                  </li>
                  <li className="mb-16">
                    <Link to="/shop" className="text-white hover-text-main-600">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/shop" className="text-white hover-text-main-600">
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-item" style={{ zIndex: "999" }}>
                <h6 className="footer-item__title" style={{ color: "#FFD0B5" }}>
                  Connect with Us
                </h6>
                <ul className="footer-menu">
                  <li className="mb-16">
                    <Link
                      to="/shop"
                      className="text-white hover-text-main-600 d-flex"
                    >
                      {/* <i className="ph ph-whatsapp-logo " /> */}
                      <img src="../assets/images/icon/Chat_Circle.svg" alt="" />
                      <p className="mx-2">+9197406616161</p>
                    </Link>
                  </li>
                  <li className="mb-16">
                    <Link
                      to="/shop"
                      className="text-white hover-text-main-600 d-flex"
                    >
                      <img src="../assets/images/icon/Mail.svg" alt="" />
                      <p className="mx-2">info@kifionline.com</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-item" style={{ zIndex: "999" }}>
                <p className="mb-16 text-white">Download our mobile app</p>
                <div className="flex-align gap-8 my-32">
                  <Link to="/https://www.apple.com/store" className="">
                    <img src="../assets/images/icon/appstore.svg" alt="" />
                  </Link>
                  <Link
                    to="/https://play.google.com/store/apps?hl=en"
                    className=""
                  >
                    <img src="../assets/images/icon/googleplay.svg" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </footer>
      </div>
      {/* Mobile Footer */}
      <footer className="mobile-footer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Link
            to="/"
            style={{ textAlign: "center", fontSize: "12px", color: "#000" }}
          >
            <i
              className="ph-fill ph-house"
              style={{
                fontSize: "24px",
                marginBottom: "4px",
                display: "block",
              }}
            ></i>
            <span>Home</span>
          </Link>

          <Link
            to="/contact"
            style={{ textAlign: "center", fontSize: "12px", color: "#000" }}
          >
            <i
              className="ph-fill ph-phone-call"
              style={{
                fontSize: "24px",
                marginBottom: "4px",
                display: "block",
              }}
            ></i>
            <span>Contact</span>
          </Link>

          <Link
            to="/cart"
            style={{ textAlign: "center", fontSize: "12px", color: "#000" }}
          >
            <i
              className="ph-fill ph-shopping-cart"
              style={{
                fontSize: "24px",
                marginBottom: "4px",
                display: "block",
              }}
            ></i>
            <span>Cart</span>
          </Link>

          <Link
            to="/account"
            style={{ textAlign: "center", fontSize: "12px", color: "#000" }}
          >
            <i
              className="ph-fill ph-user-circle"
              style={{
                fontSize: "24px",
                marginBottom: "4px",
                display: "block",
              }}
            ></i>
            <span>Account</span>
          </Link>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
