import React, { createContext, useState, useEffect, useContext } from "react";

export const WarehouseContext = createContext();

export const WarehouseProvider = ({ children }) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Load selected warehouse from localStorage on mount
  useEffect(() => {
    const storedWarehouse = localStorage.getItem("selectedWarehouse" || 12);
    if (storedWarehouse) {
      setSelectedWarehouse(JSON.parse(storedWarehouse));
    } else {
      setIsModalOpen(true); // Open modal if no warehouse is selected
    }
  }, []);

  // Save selected warehouse to localStorage whenever it changes
  useEffect(() => {
    if (selectedWarehouse) {
      localStorage.setItem(
        "selectedWarehouse",
        JSON.stringify(selectedWarehouse)
      );
    }
  }, [selectedWarehouse]);

  const changeWarehouse = (warehouse) => {
    setSelectedWarehouse(warehouse);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <WarehouseContext.Provider
      value={{
        selectedWarehouse,
        changeWarehouse,
        isModalOpen,
        openModal,
        closeModal,
      }}
    >
      {children}
    </WarehouseContext.Provider>
  );
};

export const useWarehouseContext = () => {
  const context = useContext(WarehouseContext);
  if (!context) {
    throw new Error("use auth context within provider");
  }
  return context;
};
