import React, { createContext, useState, useEffect, useContext } from "react";
import { CartContext } from "./CartContext";
import { addToRemotCart } from "../api/cartApi";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const cartcontext = useContext(CartContext);
  const { cart: localcart } = cartcontext;
  // console.log(cart, "cart");

  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) || false
  );
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("userId")) || null
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(JSON.parse(localStorage.getItem("isLoggedIn")));
      setUserId(JSON.parse(localStorage.getItem("userId")));
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const login = (userid) => {
    // localStorage.setItem('cart',null)
    localStorage.setItem("isLoggedIn", JSON.stringify(true));
    localStorage.setItem("userId", JSON.stringify(userid));
    localcart?.forEach((item) => {
      // console.log(item);
      addToRemotCart(item?.item?.id, item?.num);
    });
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.setItem("isLoggedIn", JSON.stringify(false));
    localStorage.setItem("userId", null);
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, userId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// export const useAuthContext = () => {
//   const Context = useContext(AuthContext);
//   if (!Context) {
//     throw new Error("use auth context within provider");
//   }
//   return Context;
// };

export default AuthContext;
