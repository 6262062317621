import AddressBook from "./AddressBook";
import { useState } from "react";
import MyProfile from "./MyProfile";
// import { addNewAddress, getUserAddress } from "../api/addressApi";

const OrderPage = () => {
  // const Navigate = useNavigate();
  // const dispatch = useDispatch<AppDispatch>();
  // let { userId } = useParams();
  const menuMappings = {
    1: "dashboard",
    2: "addressBook",
    3: "order",
    4: "refferal",
    5: "coupon",
    6: "profile",
    7: "terms",
    8: "Account/delete",
  };
  const id = 1;

  const selectedMenu = menuMappings[id] || "dashboard";

  const [menuState, setMenuState] = useState({
    activeMenu: selectedMenu,
  });
  const handleMenuChange = (menu) => {
    setMenuState({ activeMenu: menu });
  };

  // const handleLogout = () => {
  //   localStorage.removeItem("AuthTokens");
  //   window.location.reload();
  // };

  const MenuItem = ({ title, menu, activeMenu, onClick }) => {
    const handleClick = () => {
      onClick(menu);
      setMenuState({ activeMenu: menu });
      if (menu) {
        // Navigate(`/${menu}`);
      }
    };
    return (
      <li
        className={`py-2.5 ${
          activeMenu === menu ? "text-secondary" : "text-pricing-offercard"
        }`}
        onClick={handleClick}
      >
        {title}
      </li>
    );
  };

  return (
    <>
      <div className="">
        <section className="">
          {/* <Header /> */}
          <div className="row d-flex">
            <section className="col-3">
              <div className=" pb-10">
                <h5 className="">MY ACCOUNT</h5>
                <ul className="list-none text-[15px] font-medium">
                  <MenuItem
                    title="Account Dashboard"
                    menu="dashboard"
                    activeMenu={menuState.activeMenu}
                    onClick={handleMenuChange}
                  />
                  <MenuItem
                    title="Address Book"
                    menu="addressBook"
                    activeMenu={menuState.activeMenu}
                    onClick={handleMenuChange}
                  />
                  <MenuItem
                    title="My Orders"
                    menu="order"
                    activeMenu={menuState.activeMenu}
                    onClick={handleMenuChange}
                  />
                  <MenuItem
                    title="My Referrals"
                    menu="refferal"
                    activeMenu={menuState.activeMenu}
                    onClick={handleMenuChange}
                  />
                  <MenuItem
                    title="Coupon"
                    menu="coupon"
                    activeMenu={menuState.activeMenu}
                    onClick={handleMenuChange}
                  />
                  <MenuItem
                    title="Edit Profile"
                    menu="profile"
                    activeMenu={menuState.activeMenu}
                    onClick={handleMenuChange}
                  />
                  <MenuItem
                    title="Terms and Condition"
                    menu="terms"
                    activeMenu={menuState.activeMenu}
                    onClick={handleMenuChange}
                  />
                  <MenuItem
                    title="Account Deletion Request"
                    menu="Account/delete"
                    activeMenu={menuState.activeMenu}
                    onClick={handleMenuChange}
                  />
                  <li
                    // onClick={handleLogout}
                    className="py-2.5 text-pricing-offercard"
                  >
                    Logout
                  </li>
                  <li>
                    <button>add new address</button>
                    {/* onClick={getUserAddress} */}
                  </li>
                </ul>
              </div>
            </section>
            <section className="col-9  ">
              {menuState.activeMenu === "profile" && <MyProfile />}

              {/* {menuState.activeMenu === "order" && (
                <MyOrder order={true} dashboard={true} />
              )}
              {menuState.activeMenu === "dashboard" && (
                <MyOrder order={false} dashboard={true} />
              )}
              {menuState.activeMenu === "Account/delete" && <DeleteAccount />}
              {menuState.activeMenu === "refferal" && <MyRefferal />} */}
              {/* {menuState.activeMenu === "coupon" && <CouponListingPage />} */}
              {menuState.activeMenu === "addressBook" && <AddressBook />}
              {/* {menuState.activeMenu === "terms" && <TermsAndCondition />} */}
            </section>
          </div>
        </section>
        {/* <section className="bg-black/[.7] hidden md:visible md:flex w-full justify-center "> */}
        {/* <FooterWhite /> */}
        {/* </section> */}
      </div>
      {/* <div className="visible md:hidden h-screen w-screen bg-white">
        <OrderListPageMobile />
      </div> */}
      {/* <div className="visible md:hidden h-screen w-screen bg-white">
        <Outlet />
      </div> */}
    </>
  );
};

export default OrderPage;
