import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../context/CartContext";
// import AuthContext from "../context/AuthContext";
// import { addToRemotCart } from "../api/cartApi";

export default function SubProductCard({ item }) {
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useContext(CartContext);
  // const { isLoggedIn } = useContext(AuthContext);
  console.log(item, "itemsss");
  // const { addToCart } = useContext(CartContext);
  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };
  const decrementQuantity = () =>
    setQuantity(quantity > 1 ? quantity - 1 : quantity);

  return (
    <div className="d-lg-flex mt-30 ">
      <div className="product-image align-item-center center my-auto">
        <img
          src={
            item?.image_url
              ? `${item?.image_url}`
              : `../assets/images/bg/testfish.png`
          }
          alt={item.name}
          style={{ width: "100%", borderRadius: "15px" }}
          className="center align-item-center"
        />
      </div>

      <div className="product-details text-black">
        <p style={{ fontWeight: "bold", fontSize: "18px" }}>{item?.name}</p>

        <div className="flex-align w-100 flex-wrap gap-16 justify-content- mt-10">
          <div className="border border-gray-200 rounde-pil  flex-align d-flex  justify-content-around">
            <button
              onClick={decrementQuantity}
              type="button"
              className="quantity__minus p-4 text-gray-700 hover-text-main-600 flex-center py-9 px-16"
              style={{ zIndex: 900 }}
            >
              <i className="ph ph-minus" style={{ fontWeight: "800" }} />
            </button>
            <input
              type="number"
              className="quantity__input border-gray-100 text-center w-52 rounde border py-9 px-16 "
              value={quantity}
              readOnly
              style={{ backgroundColor: "var(--bg-pale-blue)" }}
            />
            <button
              onClick={incrementQuantity}
              type="button"
              style={{ zIndex: 900 }}
              className="quantity__plus p-4 text-gray-700 hover-text-main-600 flex-center py-9 px-16"
            >
              <i className="ph ph-plus " style={{ fontWeight: "800" }} />
            </button>
          </div>
          <Link
            to="#"
            onClick={() => {
              // isLoggedIn ? addToCart(item, quantity) && addToRemotCart(item.id, quantity):
              addToCart(item, quantity);
            }}
            className="btn btn-main flex-align d-inline-flex gap-8 px-32"
            style={{ borderRadius: "15px" }}
          >
            Add To Cart
          </Link>
        </div>

        <div className="price-info d-flex mt-10 ">
          <p>Final price: ₹{item.price}</p>
        </div>
      </div>
    </div>
  );
}
