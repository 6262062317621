import React from "react";
import { Sidebar } from "./CheckoutSidebar";

function Confirmation() {
  return (
    <section className="checkout py-40">
      <div className="container container-lg ">
        <div className="d-flex">
          <div className="flex-column col-7">
            <p
              className=""
              style={{
                alignContent: "start",
                fontWeight: "600",
                color: "#004781",
                fontSize: "24px",
              }}
            >
              Order S0000
            </p>
            <div className="w-100 d-flex  justify-content-center mb-40">
              <img
                className="w-50"
                src="/assets/images/bg/order_confirm.svg"
                alt=""
              />
            </div>
            <div
              className=" text-center d-flex  align-items-center justify-content-center"
              style={{
                backgroundColor: "#004781",
                height: "89px",
                width: "90%",
              }}
            >
              <p
                className="text-white "
                style={{
                  fontSize: "24px",
                }}
              >
                Your order has received
              </p>
            </div>
            <p
              className="mt-20"
              style={{
                alignContent: "start",
                fontWeight: "600",
                color: "#004781",
                fontSize: "24px",
              }}
            >
              Payment Information
            </p>
          </div>
          <div className="col-5">
            <Sidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Confirmation;
