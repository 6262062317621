import React, { useContext } from "react";
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "./Bannertwo.styles.css";
import { WarehouseContext } from "../context/WarehouseContext";
import { useWarehouseData } from "../hooks/useWarehouseData";
const BannerTwo = () => {
  const { selectedWarehouse } = useContext(WarehouseContext);
  const { data } = useWarehouseData(selectedWarehouse);
  const banners = data?.result?.banners;
  //   console.log(data, banners, "bannnnneeedataaaaaaaa");

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  const settingsmob = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <div className="banner-two">
      <div className="container container-lg d-none d-lg-block">
        <div className="banner-two-wrapper d-flex align-items-start">
          <div className="banner-item-two-wrapper rounded-24 overflow-hidden position-relative arrow-center flex-grow-1 mb-0 ">
            <div className="banner-item-two__slider ">
              <Slider {...settings}>
                {banners
                  ? banners.map((item) => (
                      <div className="banner-item-two" key={item.id}>
                        <img
                          src={item.image_url}
                          alt="banner"
                          className="banner-img position-absolute inset-block-start-0 inset-inline-start-0 w-100  z-n1 object-fit-cover rounded-24"
                        />
                        <div className="banner-item-two__thumb position-absolute bottom-0">
                          {/* <img src="assets/images/thumbs/banner-two-img.png" alt="" /> */}
                        </div>
                      </div>
                    ))
                  : ""}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="container container-lg d-block d-lg-none">
        <div className="banner-two-wrapper d-flex align-items-start">
          <div className="banner-item-two-wrapper rounded-24 overflow-hidden position-relative arrow-center flex-grow-1 mb-0 ">
            <div className="banner-item-two__slider height">
              <Slider {...settingsmob}>
                {banners
                  ? banners.map((item) => (
                      <div className="banner-item-two" key={item.id}>
                        <img
                          src={item.image_url}
                          alt="banner"
                          className="banner-img position-absolute inset-block-start-0 inset-inline-start-0 w-100  z-n1 object-fit-cover rounded-24"
                        />
                        <div className="banner-item-two__thumb position-absolute bottom-0">
                          {/* <img src="assets/images/thumbs/banner-two-img.png" alt="" /> */}
                        </div>
                      </div>
                    ))
                  : ""}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerTwo;
