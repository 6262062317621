import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import QuantityControl from "../helper/QuantityControl";
import { CartContext } from "../context/CartContext";
import AuthContext from "../context/AuthContext";
import OrderProgress from "./OrderProgress";

const CartSection = () => {
  const [quantity, setQuantity] = useState(1);
  const auth = useContext(AuthContext);
  const { isLoggedIn } = auth;
  const { cart, increment, decrement, removeFromCart } =
    useContext(CartContext);

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };
  const decrementQuantity = () =>
    setQuantity(quantity > 1 ? quantity - 1 : quantity);
  const handlecheckout = () => {
    // addToRemotCart()
  };
  // console.log(cart, "cartttttt");

  return (
    <section className="cart py-80">
      <div className="container container-lg">
        <div className="row gy-4">
          <div className=" col-lg-8 col-12">
            <div className="cart-table borde borde-gray-100 rounded-8 px-5 py-48">
              {/* ------------------------------------------------------------- */}

              <div className="d-flex flex-column d-md-none">
                {
                  cart?.length !== 0
                    ? cart?.map((el) => (
                        <div
                          key={el.item.id}
                          className="w-100 shadow-sm p-3 mb-5 bg-white rounded py-10 px-10 d-flex"
                        >
                          <div className="d-flex flex-column justify-content-between">
                            <div>
                              <h6 className="title text-lg fw-semibold mb-8">
                                {el.item.name}
                              </h6>
                              <p className="text-lg h6 my-10 fw-semibold">
                                ₹ {el.item.price}
                              </p>
                              <p className="text-lg h6 my-10 fw-semibold">
                                Total:{el.item.price * el.num}
                              </p>
                              {/* <p>1 pack</p> */}
                            </div>
                            <div className="d-flex">
                              {" "}
                              <img
                                src={`assets/images/icon/bin.svg`}
                                alt="delete item"
                                role="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  removeFromCart(el);
                                }}
                              />
                              <p
                                className="ms-3"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  removeFromCart(el);
                                }}
                              >
                                Remove
                              </p>
                            </div>
                          </div>
                          <div>
                            <img
                              src={
                                el.image_url
                                  ? `${el.image_url}`
                                  : `assets/images/bg/testfish.png`
                              }
                              alt="item"
                              className=" w-65 h-65 rounded mb-10"
                            />
                            <div className="border border-gray-200 rounde-pil  flex-align d-flex  justify-content-around">
                              <button
                                onClick={() => {
                                  decrementQuantity();
                                  decrement(el);
                                }}
                                type="button"
                                className="quantity__minus p-4 text-gray-700 hover-text-main-600 flex-center py-9 px-16"
                                style={{ zIndex: 900 }}
                              >
                                <i
                                  className="ph ph-minus"
                                  style={{ fontWeight: "800" }}
                                />
                              </button>
                              <input
                                type="number"
                                className="quantity__input border-gray-100 text-center w-52 rounde border py-9 px-16 "
                                value={el.num}
                                readOnly
                                style={{
                                  backgroundColo: "var(--bg-pale-blue)",
                                }}
                              />
                              <button
                                onClick={() => {
                                  incrementQuantity();
                                  increment(el);
                                }}
                                type="button"
                                style={{ zIndex: 900 }}
                                className="quantity__plus p-4 text-gray-700 hover-text-main-600 flex-center py-9 px-16"
                              >
                                <i
                                  className="ph ph-plus "
                                  style={{ fontWeight: "800" }}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    : `${cart.length}`
                  // "Please add items to cart"
                }
              </div>
              {/* ------------------------------------------------------------- */}
              <OrderProgress step={1} />
              <div className="overflow-x-auto scroll-sm scroll-sm-horizontal d-none d-md-block col-12">
                {cart.length !== 0 ? (
                  <table className="table style-three">
                    <thead>
                      <tr>
                        <th className="h6 mb-0 text-lg fw-bold">Product </th>
                        <th className="h6 mb-0 text-lg fw-bold">Quantity</th>
                        <th className="h6 mb-0 text-lg fw-bold">Price</th>
                        <th className="h6 mb-0 text-lg fw-bold"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((item) => (
                        <tr key={item.item.id}>
                          <td>
                            <div className="table-product d-flex align-items-center  gap-24">
                              <img
                                src={
                                  item.item.image_url
                                    ? `${item.item.image_url}`
                                    : `assets/images/bg/testfish.png`
                                }
                                alt=""
                                className="rounded-10 w-120"
                              />
                              {/* </Link> */}
                              <div className="table-product__content text-start ">
                                <h6
                                  className="title text-lg text-wrap fw-semibold mb-8 "
                                  style={{ width: "300px" }}
                                >
                                  {item.item.name}
                                </h6>
                              </div>
                            </div>
                          </td>

                          <td>
                            <QuantityControl
                              initialQuantity={1}
                              item={item}
                              increment={increment}
                              decrement={decrement}
                            />
                          </td>
                          <td>
                            <span className="text-lg h6 mb-0 fw-semibold">
                              ₹ {item.item.price}
                            </span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icon/bin.svg"
                              alt="delete item"
                              role="button"
                              onClick={() => removeFromCart(item)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="center">your cart is empty add some items</p>
                )}
                <div>
                  <Link
                    to={"/shop"}
                    type="submit"
                    style={{
                      border: "2px solid #004781",
                      borderRadius: "15px",
                      backgroundColor: "transparent",
                      padding: "10px 20px",
                      color: "#004781",
                    }}
                  >
                    <img src="/assets/images/icon/left_arrow.png" alt="" />
                    Continue Shopping
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="cart-sidebar borde border-gray-100 rounded-8 px-24 py-40"
              style={{ background: " #F4FAFF" }}
            >
              <h6 className="text-xl mb-32">Order Total</h6>
              <div className="bg-color- rounded-8 p-24 bg- border-top">
                <div className="mb-32 flex-between gap-8">
                  <span className="text-gray-900 font-heading-two">
                    Subtotal
                  </span>
                  <span className="text-gray-900 fw-semibold">
                    ₹
                    {cart.reduce((tot, item) => {
                      return parseFloat(
                        (tot + item.item.price * item.num).toFixed(2)
                      );
                    }, 0)}
                  </span>
                </div>
                <div className="mb-32 flex-between gap-8">
                  <span className="text-gray-900 font-heading-two">
                    Extimated Delivery
                  </span>
                  <span className="text-gray-900 fw-semibold">Free</span>
                </div>
                {/* <div className="mb-0 flex-between gap-8">
                  <span className="text-gray-900 font-heading-two">
                    Extimated Taxs
                  </span>
                  <span className="text-gray-900 fw-semibold">₹.10.00</span>
                </div> */}
              </div>
              <div className="bg-color-thre rounded-8 p-24 mt-24 border-top">
                <div className="flex-between gap-8">
                  <span className="text-gray-900 text-xl fw-semibold">
                    Total
                  </span>
                  <span className="text-gray-900 text-xl fw-semibold">
                    ₹
                    {cart.reduce((tot, item) => {
                      return parseFloat(
                        (tot + item.item.price * item.num).toFixed(2)
                      );
                    }, 0)}
                  </span>
                </div>
              </div>
              <div className="flex-align  ga mt-20">
                <input
                  type="text"
                  className="common-input bg-white py-18"
                  placeholder="Coupon Code"
                  style={{
                    borderBottomLeftRadius: "13px",
                    borderTopLeftRadius: "13px",
                  }}
                />
                <button
                  type="submit"
                  className=" btn-main py-18 w-50 "
                  // style={{}}
                  style={{
                    borderBottomRightRadius: "13px",
                    borderTopRightRadius: "13px",
                  }}
                >
                  Apply
                </button>
              </div>
              <Link
                to={
                  isLoggedIn
                    ? { pathname: "/checkout" }
                    : { pathname: "/account" }
                }
                className="btn btn-main mt-40 py-18 w-100 rounded-8"
                onClick={handlecheckout}
              >
                Proceed to checkout
              </Link>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CartSection;
