import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../context/CartContext";
// import cartc
export default function OfferzoneCard({ item }) {
  const [quantity, setQuantity] = useState(1);
  const incrementQuantity = () => setQuantity(quantity + 1);
  const decrementQuantity = () =>
    setQuantity(quantity > 1 ? quantity - 1 : quantity);
  // console.log(item, "iteeeem");
  const { addToCart } = useContext(CartContext);

  return (
    <div className="col-md-3 col-sm-4 col-6 mb-24">
      <div
        // style={{
        //   minHeight: "400px",
        // }}
        className="product-car pb-16  border-none hove-border-main-600 rounded-16 position-relative transition-2 bg-white"
      >
        <Link
          // to="/cart"
          className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 position-absolute inset-block-start-0 inset-inline-end-0 me-16 mt-16"
        >
          <i className="ph ph-heart" />
        </Link>
        <p className="product-card__thub flex-center">
          <img
            src={
              item?.image_url
                ? `${item?.image_url}`
                : `assets/images/bg/testfish.png`
            }
            style={{
              width: "504px",
              height: "100%",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
            alt="prod"
          />
        </p>
        <div className="product-card__content mt-12 mx-12">
          <h6 className="title text-lg fw-semibold mt-12 mb-8 ">
            <p className="link text-line-2 ">{item?.name}</p>
          </h6>
          <div className="product-card__price mb-16">
            {/* <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
              ₹{item.offer_price}
            </span> */}
            <span className="text-heading text-md fw-semibold">
              ₹{item?.price}{" "}
              {/* <span className="text-gray-500 fw-normal"></span> */}
            </span>
          </div>
          {/* <div className="flex-align gap-4"></div> */}
          {/* <div className="flex-between  flex-wrap"> */}
          <div className="flex-align w-100 flex-wrap gap-10 justify-content-around ">
            <div className="borde borde-gray-100 rounded-pil py-9 px-16 flex-align d-flex  justify-content-around">
              <button
                onClick={decrementQuantity}
                type="button"
                className="quantity__minus p-4 text-gray-700 hover-text-main-600 flex-center"
                style={{ zIndex: 900 }}
              >
                <i className="ph ph-minus" style={{ fontWeight: "800" }} />
              </button>
              <input
                type="number"
                className="quantity__input border-0 text-center w-44 px-4 py-4 rounded"
                value={quantity}
                readOnly
                style={{ backgroundColor: "var(--bg-pale-blue)" }}
              />
              <button
                onClick={incrementQuantity}
                type="button"
                style={{ zIndex: 900 }}
                className="quantity__plus p-4 text-gray-700 hover-text-main-600 flex-center"
              >
                <i className="ph ph-plus " style={{ fontWeight: "800" }} />
              </button>
            </div>
            <Link
              to="#"
              className="btn btn-main flex-align d-inline-flex gap-8 px-32"
              style={{ borderRadius: "15px" }}
              onClick={() => addToCart(item, quantity)}
            >
              Add To Cart
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
