import axios from "axios";
// const API_BASE_URL = "https://kifitest.zbeanztech.com";
// const API_BASE_URL = "http://97.74.86.77:8007";
const API_BASE_URL = "https://kifionline.in";

export const genarateOtp = async function (mobile) {
  const response = await axios.post(API_BASE_URL + "/generate/otp/", {
    params: {
      mobile: `${mobile}`,
    },
  });
  return response;
  // console.log("Response Headers:", response);
};

export const verifyOtp = async function (mobile, otp) {
  try {
    const response = await axios.post(API_BASE_URL + "/verify/otp/", {
      params: {
        mobile: `${mobile}`,
        otp,
        username: "john_doe",
        password: "secure_password123",
      },
    });
    console.log("Response Headers:", response?.data?.result);
    return response?.data?.result;
  } catch (err) {
    console.error(err.message);
    return;
  }
};
