import React from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
// import ProductDetailsOne from "../components/ProductDetailsOne";

// import ShippingOne from "../components/ShippingOne";
// import NewsletterOne from "../components/NewsletterOne";
import FooterOne from "../components/FooterOne";
import BottomFooter from "../components/BottomFooter";

import ScrollToTop from "react-scroll-to-top";
import ColorInit from "../helper/ColorInit";
// import NewArrivalOne from "../components/NewArrivalOne";
// import Breadcrumb from "../components/Breadcrumb";
import ProductPagelist from "../components/ProductPagelist";
import Footertop from "../components/Footertop";
import { useParams } from "react-router-dom";

const ProductDetailsPageOne = () => {
  const { id } = useParams();
  // console.log(id);

  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* ColorInit */}
      <ColorInit color={false} />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#299E60" />

      {/* HeaderOne */}
      <HeaderOne />

      {/* Breadcrumb */}
      {/* <Breadcrumb title={"Product Details"} /> */}

      {/* ProductDetailsOne */}
      {/* <ProductDetailsOne /> */}
      <ProductPagelist id={id} />

      {/* NewArrivalTwo */}
      {/* <NewArrivalOne /> */}

      {/* ShippingOne */}
      {/* <ShippingOne /> */}
      <Footertop />
      {/* NewsletterOne */}
      {/* <NewsletterOne /> */}

      {/* FooterTwo */}
      <FooterOne />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default ProductDetailsPageOne;
