import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { WarehouseContext } from "../context/WarehouseContext";
import { useWarehouseData } from "../hooks/useWarehouseData";
import { CategoryContext } from "../context/CategoryContext";
// import { useQuery } from '@tanstack/react-query'

export default function Category() {
  const { selectedWarehouse } = useContext(WarehouseContext);

  const { data } = useWarehouseData(selectedWarehouse);
  const { changeSubCategory } = useContext(CategoryContext);
  // console.log(data,'dtaaaaaaaaaaaaaaaaaaaaaaaaa');
  const categories = data?.result?.child_categories;
  function handleSubCategoryChange(id) {
    changeSubCategory(id);
  }
  return (
    <div>
      <section>
        <h3
          className="container my-12 pb-8 font-secondary"
          style={{ color: "var(--secondary)" }}
        >
          {" "}
          shop by category{" "}
        </h3>
        <div className="container mt-5">
          <div className="row">
            {categories?.map((cat) => {
              return (
                <div
                  key={cat.id}
                  className="col-lg-3 col-sm-4 col-md-4 col-4 mb-4 "
                >
                  <a
                    href="/shop"
                    className="column-link d-flex align-item-center"
                    role="button"
                    onClick={() => handleSubCategoryChange(cat.id)}
                  >
                    <div
                      className="img-container-categor mx-auto bg-white d-lg-none"
                      style={{
                        // width: "10vw",
                        // height: "10vw",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={
                          cat.image_url
                            ? `${cat.image_url}`
                            : `assets/images/bg/testfish.png`
                        }
                        className="w-300 h-200 object-fit-cover"
                        alt="category"
                      />
                    </div>
                    <div
                      className="img-container-categor mx-auto bg-white d-none d-lg-block  "
                      style={{
                        width: "10vw",
                        height: "10vw",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={
                          cat.image_url
                            ? `${cat.image_url}`
                            : `assets/images/bg/testfish.png`
                        }
                        className="w-100 h-100   object-fit-cover"
                        alt="category"
                      />
                    </div>
                    <strong className="d-flex justify-content-center">
                      <font />
                    </strong>
                  </a>

                  <h6 className="text-center">
                    <Link
                      to="/shop"
                      style={{ color: "var(--secondary)", fontSize: "20px" }}
                      onClick={() => handleSubCategoryChange(cat.id)}
                    >
                      {cat?.name?.length > 20
                        ? `${cat.name.substring(0, 20)}...`
                        : cat.name}
                    </Link>
                  </h6>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
