import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../context/CartContext";
// import { NewAddress } from "./NewAddress";
// import { AddressContext } from "../context/AddressContext";

import { getUserAddress } from "../api/addressApi";
import OrderProgress from "./OrderProgress";
import CheckoutSidebar from "./CheckoutSidebar";
// import AddressBook from "./AddressBook";

const Checkout = () => {
  // const [selectedPayment, setSelectedPayment] = useState("payment1");

  const addressRef = useRef([]); // Store addresses
  const [, setRenderTrigger] = useState(false); // Trigger re-render
  const [checked, setChecked] = useState(true);
  const handleToggle = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const res = await getUserAddress();
        addressRef.current = res?.data?.result?.data?.address_list || [];

        setRenderTrigger(true);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, []);
  const addr = addressRef.current.find((addr) => addr.type === "delivery");

  // const [total] = useState(
  //   cart.reduce((tot, item) => {
  //     return parseFloat((tot + item.item.price * item.num).toFixed(2));
  //   }, 0)
  // );

  return (
    <section className="checkout py-40">
      <div className="container container-lg ">
        <OrderProgress step={2} />
      </div>
      <div className="container container-lg d-flex">
        <div className="col-8">
          {/*----------Address------------- */}
          <div className="bg-main-50 rounded-10 px-20 py-10 pt-30 ">
            {/* <span className="font-weight-bold">Delivery Address</span>
          <hr /> */}
            <div className="d-flex justify-content-between">
              <p
                className="font-weight-bold"
                style={{
                  color: "#004781",
                  fontWeight: "600",
                  fontSize: "20px",
                  // lineHeight: "27.28px",
                }}
              >
                Billing & Shipping:
              </p>
              <span className="text-main-700">Edit</span>
            </div>
            <div style={{ marginBottom: "1rem" }} className="col-12 ">
              <p>
                <strong>Street:</strong> {addr?.address1},
              </p>

              <p>
                <strong>Landmark:</strong> {addr?.landmark}
              </p>
              <p>
                <strong>Postcode:</strong> {addr?.postcode}
              </p>
              <p>
                <strong>Phone:</strong> {addr?.address2}
              </p>
            </div>
          </div>
          {/*------------Choose Delivery-------- */}
          <div
            className="my-30"
            style={{
              color: "#004781",
              fontWeight: "600",
              fontSize: "32.74px",
              // lineHeight: "27.28px",
            }}
          >
            Choose Delivery
          </div>
          <div className="bg-main-50 rounded-10 px-20 py-20  ">
            <div className="d-flex gap-10">
              <input
                type="radio"
                id="toggleRadio"
                checked={checked}
                onChange={handleToggle} // Toggle the checked state
                style={{ display: "none" }} // Hide default radio button
              />
              <label
                htmlFor="toggleRadio"
                style={{
                  position: "relative",
                  paddingLeft: "30px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "black",
                  display: "inline-block",
                  lineHeight: "20px",
                }}
              >
                Home Delivery
                <span
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "20px",
                    height: "20px",
                    border: "2px solid #FF5E00",
                    borderRadius: "50%",
                    background: checked ? "#FF5E00" : "transparent",
                    transition: "background 0.3s ease",
                  }}
                />
              </label>
            </div>
          </div>
          <div className="d-flex gap-10 my-30">
            <input type="checkbox" />
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#24B43B",
              }}
            >
              Use Refferal Earnings
            </p>
          </div>
          {/* <div>
            <h4>Pay With</h4>
          </div> */}
          <div>
            <Link
              to={"/shop"}
              type="submit"
              style={{
                border: "2px solid #004781",
                borderRadius: "15px",
                backgroundColor: "transparent",
                padding: "10px 20px",
                color: "#004781",
              }}
            >
              {/* <i className="ph-bold ph-10" />
               */}
              <img src="/assets/images/icon/left_arrow.png" alt="" />
              Continue Shopping
            </Link>
          </div>
        </div>
        {/* ------------------- */}
        <CheckoutSidebar />
        {/* ------------------------------------------------- */}
      </div>
    </section>
    // <section className="checkout py-80">
    //   <div className="container container-lg">
    //     <div className="border border-gray-100 rounded-8 px-30 py-20 mb-20">
    //       <span className="">
    //         Have a coupon?{" "}
    //         <Link
    //           to="/cart"
    //           className="fw-semibold text-gray-900 hover-text-decoration-underline hover-text-main-600"
    //         >
    //           Click here to enter your code
    //         </Link>{" "}
    //       </span>
    //     </div>

    //     <div className="row">
    //       {/* -------------------address----------------- */}
    //       {addr?.length <= 0 ? (
    //         <NewAddress />
    //       ) : (
    //         <>
    //           <div>
    //             <span className="font-weight-bold">Delivery Address</span>
    //             <hr />
    //             <div style={{ marginBottom: "1rem" }} className="col-12 ">
    //               <p>
    //                 <strong>Address:</strong> {addr?.address1},
    //               </p>
    //               <p>
    //                 <strong>Phone:</strong> {addr?.address2}
    //               </p>
    //               <p>
    //                 <strong>City:</strong> {addr?.city}
    //               </p>
    //               <p>
    //                 <strong>Landmark:</strong> {addr?.landmark}
    //               </p>
    //               <p>
    //                 <strong>Postcode:</strong> {addr?.postcode}
    //               </p>
    //               <p>
    //                 <strong>State:</strong> {addr?.state}
    //               </p>
    //               <p>
    //                 <strong>Country:</strong> {addr?.country}
    //               </p>
    //               <p>
    //                 <strong>Email:</strong> {addr?.email}
    //               </p>

    //               <p>
    //                 <strong>Type:</strong> {addr?.type}
    //               </p>
    //             </div>
    //             <hr />
    //           </div>
    //           <div className="col-xl-12 col-lg-12">
    //             <div className="checkout-sidebar">
    //               <div className="bg-color-three rounded-8 p-24 text-center">
    //                 <span className="text-gray-900 text-xl fw-semibold">
    //                   Your Orders
    //                 </span>
    //               </div>
    //               <div className="border border-gray-100 rounded-8 px-24 py-40 mt-24">
    //                 <div className="mb-32 pb-32 border-bottom border-gray-100 flex-between gap-8">
    //                   <span className="text-gray-900 fw-medium text-xl font-heading-two">
    //                     Product
    //                   </span>
    //                   <span className="text-gray-900 fw-medium text-xl font-heading-two">
    //                     Subtotal
    //                   </span>
    //                 </div>
    //                 {cart?.map((item) => (
    //                   <div className="flex-between gap-24 mb-32" key={item.id}>
    //                     <div className="flex-align gap-12">
    //                       <span className="text-gray-900 fw-normal text-md font-heading-two w-144">
    //                         {item?.item?.name}
    //                       </span>
    //                       <span className="text-gray-900 fw-normal text-md font-heading-two">
    //                         <i className="ph-bold ph-x" />
    //                       </span>
    //                       <span className="text-gray-900 fw-semibold text-md font-heading-two">
    //                         {item?.num}
    //                       </span>
    //                     </div>
    //                     <span className="text-gray-900 fw-bold text-md font-heading-two">
    //                       {(item?.item.price * item?.num).toFixed(2)}
    //                     </span>
    //                   </div>
    //                 ))}

    //                 <div className="border-top border-gray-100 pt-30  mt-30">
    //                   <div className="mb-32 flex-between gap-8">
    //                     <span className="text-gray-900 font-heading-two text-xl fw-semibold">
    //                       Total
    //                     </span>
    //                     <span className="text-gray-900 font-heading-two text-md fw-bold">
    //                       ₹{total}
    //                     </span>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="mt-32 pt-32 border-top border-gray-100">
    //                 <p className="text-gray-500">
    //                   Your personal data will be used to process your order,
    //                   support your experience throughout this website, and for
    //                   other purposes described in our{" "}
    //                   <Link
    //                     to="#"
    //                     className="text-main-600 text-decoration-underline"
    //                   >
    //                     {" "}
    //                     privacy policy
    //                   </Link>{" "}
    //                   .
    //                 </p>
    //               </div>
    //               {/* <Link
    //               to={{
    //                 pathname: "/checkout",
    //                 state: { paymentMethod: selectedPayment, total },
    //               }}
    //               className="btn btn-main mt-40 py-18 w-100 rounded-8 mt-56"
    //             >
    //               Place Order
    //             </Link> */}
    //               <PaymentGateway />
    //             </div>
    //           </div>
    //         </>
    //       )}
    //       {/* -------------------product list----------------- */}
    //     </div>
    //   </div>
    // </section>
  );
};

export default Checkout;
