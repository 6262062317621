import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const OrderProgress = (step) => {
  const stages = ["Review Order", "Selecting Address", "Confirm Order"];
  const [currentStage, setCurrentStage] = useState(0);

  const handleStageChange = (index) => {
    setCurrentStage(index);
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center position-relative">
        {/* Horizontal Line */}
        <div
          className="position-absolute"
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#E8E8E8",
            top: "50%",
            left: 0,
            zIndex: 1,
          }}
        ></div>

        {/* Stages */}
        {stages.map((stage, index) => (
          <div
            key={index}
            className="text-center position-relative mb-20"
            style={{ zIndex: 2 }}
          >
            {/* Radio Button */}
            <label htmlFor={`stage-${index}`} className="mt-">
              {stage}
            </label>
            <div>
              <input
                type="radio"
                id={`stage-${index}`}
                name="order-progress"
                checked={currentStage === index}
                onChange={() => handleStageChange(index)}
                className="form-check-input"
                style={{
                  transform: "scale(1.2)",
                  position: "relative",
                  top: "-50%",
                }}
              />
            </div>
            {/* Stage Label */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderProgress;
