// src/components/WarehouseSelector.jsx
import React, { useContext } from "react";
import { WarehouseContext } from "../context/WarehouseContext";
// import WarehouseModal from './WarehouseModal';
import LocationModal from "./LocationModal";

const WarehouseSelector = () => {
  const { isModalOpen } = useContext(WarehouseContext);

  return isModalOpen ? <LocationModal /> : null;
  // return <LocationModal />;
};

export default WarehouseSelector;
//
