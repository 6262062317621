import React from "react";

const QuantityControl = ({ increment, decrement, item }) => {
  return (
    <div
      className="d-flex rounded-4 overflow-hidden "
      style={{ width: "200px" }}
    >
      <button
        type="button"
        onClick={() => decrement(item)}
        className="quantity__minus border border-primary-700 border-end  flex-shrink-0 h-48 w-48 text-neutral-600 flex-center hover-bg-main-600 hover-text-white"
        // style={{ border: "#004781" }}
      >
        <i className="ph ph-minus" />
      </button>
      <input
        type="number"
        className="quantity__input flex-grow-1 border border-primary-700 border-start-0 border-end-0 text-center w-32 px-4"
        value={item.num}
        min={1}
        readOnly
      />
      <button
        type="button"
        onClick={() => increment(item)}
        className="quantity__plus border border-primary-700 border-end border-gray-100 flex-shrink-0 h-48 w-48 text-neutral-600 flex-center hover-bg-main-600 hover-text-white"
      >
        <i className="ph ph-plus" />
      </button>
    </div>
  );
};

export default QuantityControl;
