export const validateAddress = (address) => {
  const errors = [];

  // if (!address.name.trim()) errors.push("Name is required.");
  if (!address.city.trim()) errors.push("City is required.");
  if (!address.street.trim()) errors.push("Street is required.");
  if (!address.landmark.trim()) errors.push("Landmark is required.");
  if (!address.zip.trim()) {
    errors.push("Zip code is required.");
  } else if (!/^\d{6}$/.test(address.zip)) {
    errors.push("Zip code must be 6 digits.");
  }
  if (!address.phone1.trim()) {
    errors.push("Primary phone number is required.");
  } else if (!/^\d{10}$/.test(address.phone1)) {
    errors.push("Primary phone number must be 10 digits.");
  }
  if (address.phone2 && !/^\d{10}$/.test(address.phone2)) {
    errors.push("Additional phone number must be 10 digits.");
  }
  if (address.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(address.email)) {
    errors.push("Invalid email address.");
  }

  return errors;
};
