import axios from "axios";

// import { useAuthContext } from "../context/AuthContext";
// import { useWarehouseContext } from "../context/WarehouseContext";
// import AuthContext from "../context/AuthContext";
// const API_BASE_URL = "https://kifitest.zbeanztech.com";
// const API_BASE_URL = "http://97.74.86.77:8007";
const API_BASE_URL = "https://kifionline.in";

// const user_id = 96;
// const UseAuthContext = useContext(AuthContext);

// const warehouse_id = 12;
export const addToRemotCart = async function (product_id, quantity) {
  // const { userId: user_id, isLoggedIn } = useAuthContext;
  // console.log(user_id, isLoggedIn, "user_id");
  const user_id = localStorage.getItem("userId") * 1;
  const warehouse_id =
    JSON.parse(localStorage.getItem("selectedWarehouse")) * 1;
  // console.log(
  //   warehouse_id,
  //   user_id,
  //   localStorage.getItem("userId"),
  //   typeof user_id,
  //   typeof warehouse_id,
  //   "user idddd"
  // );
  try {
    const response = await axios.post(`${API_BASE_URL}/create_sale_order`, {
      params: {
        user_id,
        warehouse_id,
        products: [{ product_id, quantity }],
      },
    });
    console.log(response.data.result, "cartaddresponse");

    return response.data.result;
  } catch (err) {
    console.error("error in adding remote cart:", err);
  }
};
// -----------------
export const getRemoteCart = async () => {
  const response = await axios.post(`${API_BASE_URL}/get_sale_order`, {
    params: {
      sale_order_id: 7224,
    },
  });
  return response;
};

export const deleteRemoteCart = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/edit_cart`, {
      params: {
        order_id: 7224,
        product_id: 22,
      },
    });
    console.log(response, "responseeee");
  } catch (err) {
    console.error("couldnt delete from cart", err);
  }
};
