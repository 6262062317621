import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { WarehouseContext } from "../context/WarehouseContext";
import { useWarehouseData } from "../hooks/useWarehouseData";
// import { useQuery } from '@tanstack/react-query'

const ProductListOne = () => {
  const { selectedWarehouse } = useContext(WarehouseContext);
  const { data } = useWarehouseData(selectedWarehouse);
  console.log(data, "dataaaaaaaa");

  const products = data?.result?.products;
  const deal = data?.result?.deals_of_the_day?.[0];
  const product_ids = deal?.product_ids;
  const dealOfTheDay = product_ids?.map((obj1) => {
    const match = products?.find((obj2) => obj1?.id === obj2?.id);
    if (match) {
      return { ...obj1, ...match };
    }
    return obj1;
  });
  // console.log(dealOfTheDay, "deeeeeel");

  // () => {
  // product_ids;
  // };
  // const dealOfTheDay = deal?.product_ids;
  return (
    <div
      className="product mt-10 "
      style={{
        backgroundColor: "var(--bg-pale-blue)",
        paddingBottom: "60px",
      }}
    >
      <div
        className="product mt-10 pt-30  d-none d-lg-flex"
        style={{
          backgroundColor: "var(--bg-pale-blue)",
        }}
      ></div>
      <div className="container container-lg">
        <h3 className="" style={{ color: "#004781" }}>
          Deal of the Day
        </h3>
        {/* <div className="row gy-4 g-12"> */}
        {/*  <div className="col-lg-5-col col-md-3 col-sm-4 col-6"> */}
        <div className="row ">
          {dealOfTheDay?.map((el) => {
            // console.log(el, "elll");

            return (
              <div
                className="col-lg-2 col-md-3 col-sm-4 col-6 mb-24 "
                key={el?.id}
              >
                <div className="product-car  pb-16 border-none rounded-16 position-relative transition-2 bg-white rounded">
                  <Link
                    to="/"
                    className="product-card__cart btn bg-white text-main-600 hover-bg-main-600 hover-text-white py-11 px-21 rounded-pill flex-align gap-8 position-absolute inset-block-start-0 inset-inline-end-0 me-16 mt-16"
                  >
                    <i className="ph ph-heart" />
                  </Link>
                  <Link
                    to={`/product-details/${el?.id}`}
                    state={{ id: el.id }}
                    className="product-card__thub flex-center"
                  >
                    <img
                      src={
                        el.image_url
                          ? `${el.image_url}`
                          : `assets/images/bg/testfish.png`
                      }
                      alt="prod"
                      style={{
                        width: "504px",
                        height: "100%",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    />
                  </Link>
                  <div className="product-card__content mt-12 px-8">
                    <h6 className="title text-lg fw-semibold mt-12 mb-8">
                      <Link
                        to={`/product-details/${el?.id}`}
                        className="link text-line-2"
                      >
                        {el?.name}
                      </Link>
                    </h6>
                    <div className="flex-align gap-4"></div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* Repeat the card structure 4 more times */}
        </div>
      </div>
    </div>

    // </div>
  );
};

export default ProductListOne;
