// import React, { useState } from "react";
// import axios from "axios";

// const WorldlinePayment = () => {
//   const [worldlinePay, setWorldlinePay] = useState(null);

//   const handleWorldlinePayment = () => {
//     const worldline = {
//       mrctCode: "givenbyprovider",
//       txn_id: "givenbyprovider",
//       amount: "500",
//       accNo: "",
//       custID: "givenbyprovider",
//       mobNo: "",
//       email: "",
//       debitStartDate: "",
//       debitEndDate: "",
//       maxAmount: "500",
//       amountType: "",
//       frequency: "",
//       cardNumber: "",
//       expMonth: "09",
//       expYear: "2024",
//       cvvCode: "201",
//       SALT: "givenbyprovider",
//     };

//     axios
//       .post("/api/your-endpoint", worldline)
//       .then((response) => {
//         alert(JSON.stringify(response.data) + " mydata");
//         if (response.data) {
//           setWorldlinePay(response.data);
//           worldlinepay(response.data.authenticity_token);
//         } else {
//           alert("Error while recharging! Please try again");
//         }
//       })
//       .catch((error) => {
//         console.error("Error occurred:", error);
//         alert("Error while recharging! Please try again");
//       });
//   };

//   const worldlinepay = (authToken) => {
//     const options = {
//       features: {
//         enableAbortResponse: true,
//         enableExpressPay: true,
//         enableInstrumentDeRegistration: true,
//         enableMerTxnDetails: true,
//       },
//       consumerData: {
//         deviceId: "ANDROIDSH2",
//         token: authToken,
//         returnUrl:
//           "https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp",
//         paymentMode: "all",
//         merchantLogoUrl:
//           "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png",
//         merchantId: "T880876",
//         currency: "INR",
//         consumerId: "c964634",
//         consumerMobileNo: "9876543210",
//         consumerEmailId: "test@test.com",
//         txnId: "1673605171184",
//         items: [
//           {
//             itemId: "first",
//             amount: "10",
//             comAmt: "0",
//           },
//         ],
//         customStyle: {
//           PRIMARY_COLOR_CODE: "#45beaa",
//           SECONDARY_COLOR_CODE: "#FFFFFF",
//           BUTTON_COLOR_CODE_1: "#2d8c8c",
//           BUTTON_COLOR_CODE_2: "#FFFFFF",
//         },
//       },
//     };

//     const paymentCallback = (response) => {
//       alert(JSON.stringify(response) + " succ");
//     };

//     const errorCallback = (error) => {
//       alert(error);
//     };

//     if (window.WLCheckout) {
//       window.WLCheckout.open(options, paymentCallback, errorCallback);
//     } else {
//       console.error("WLCheckout library is not loaded");
//     }
//   };

//   return (
//     <div>
//       <button onClick={handleWorldlinePayment}>Make Payment</button>
//     </div>
//   );
// };

// export default WorldlinePayment;
// ---------------------------------------------------------------------------------------------
//
import React, { useEffect } from "react";
//
const PaymentGateway = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initializePayment = () => {
    // Payment initialization logic after jQuery is loaded
    window
      .$(document)
      .off("click", "#btnSubmit")
      .on("click", "#btnSubmit", function (e) {
        e.preventDefault();
        //
        const reqJson = {
          features: {
            enableAbortResponse: true,
            enableExpressPay: true,
            enableInstrumentDeRegistration: true,
            enableMerTxnDetails: true,
          },
          consumerData: {
            deviceId: "WEBSH2",
            token:
              // " 6375b97b954b37f956966977e5753ee6",
              "9ccb9b742e41b1f0a1c1febdc91fe5182ef3f99e6f04bb6f4574be4a001b821d75989c85d5bf02e097e4dba9e284ebebe898ed8540dcbc8c869e46e329d8c8d6",
            returnUrl:
              // "/",
              "https://pgproxyuat.in.worldline-solutions.com/linuxsimulator/MerchantResponsePage.jsp",
            responseHandler: handleResponse,
            paymentMode: "all",
            merchantLogoUrl:
              "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png",
            merchantId: "L3348",
            currency: "INR",
            consumerId: "c964634",
            txnId: "1731996397741",
            items: [
              {
                itemId: "first",
                amount: "1",
                comAmt: "9",
              },
            ],
            customStyle: {
              PRIMARY_COLOR_CODE: "#45beaa",
              SECONDARY_COLOR_CODE: "#FFFFFF",
              BUTTON_COLOR_CODE_1: "#2d8c8c",
              BUTTON_COLOR_CODE_2: "#FFFFFF",
            },
          },
        };

        window.$.pnCheckout(reqJson);
        if (reqJson.features.enableNewWindowFlow) {
          window.pnCheckoutShared.openNewWindow();
        }
      });
  };
  useEffect(() => {
    // Load jQuery dynamically
    const loadJQuery = document.createElement("script");
    loadJQuery.src =
      "https://www.paynimo.com/paynimocheckout/client/lib/jquery.min.js";
    loadJQuery.type = "text/javascript";
    loadJQuery.onload = initializePayment; // Initialize the payment logic once jQuery is loaded
    document.body.appendChild(loadJQuery);

    // Load Checkout script dynamically
    const loadCheckoutScript = document.createElement("script");
    loadCheckoutScript.src =
      "https://www.paynimo.com/paynimocheckout/server/lib/checkout.js";
    loadCheckoutScript.type = "text/javascript";
    document.body.appendChild(loadCheckoutScript);

    // return () => {
    //   // Cleanup scripts when the component unmounts
    //   document.body.removeChild(loadJQuery);
    //   document.body.removeChild(loadCheckoutScript);
    // };
  }, [initializePayment]);

  const handleResponse = (res) => {
    if (
      res &&
      res.paymentMethod &&
      res.paymentMethod.paymentTransaction &&
      res.paymentMethod.paymentTransaction.statusCode
    ) {
      const statusCode = res.paymentMethod.paymentTransaction.statusCode;
      if (statusCode === "0300") {
        console.log("Payment Success!");
      } else if (statusCode === "0398") {
        console.log("Payment Initiated.");
      } else {
        console.log("Payment Failed.");
      }
    }
  };

  return (
    <div>
      <button
        id="btnSubmit"
        className="btn btn-main mt-40 py-18 w-100 rounded-8"
      >
        Proceed to Pay
      </button>
    </div>
  );
};

export default PaymentGateway;
