import React, { useContext } from "react";

import OfferzoneCard from "./OfferzoneCard";
// import { useQuery } from '@tanstack/react-query'
import { WarehouseContext } from "../context/WarehouseContext";
import { useWarehouseData } from "../hooks/useWarehouseData";
// import { useQuery } from '@tanstack/react-query'

const OfferZone = () => {
  const { selectedWarehouse } = useContext(WarehouseContext);

  const { data } = useWarehouseData(selectedWarehouse);
  console.log(data);
  const offers = data?.result?.offers[0]?.product_ids || [];
  console.log(offers, "fooffferrsss");

  return (
    <div
      className="product mt-24 "
      style={{
        backgroundColor: " #FFF7F2",
        paddingTop: "60px",
        paddingBottom: "60px",
      }}
    >
      <div className="container container-lg">
        <h3 className="text-main-600" style={{ color: "#004781" }}>
          OfferZone{" "}
        </h3>
        {/* <div className="row gy-4 g-12"> */}
        {/*  <div className="col-lg-5-col col-md-3 col-sm-4 col-6"> */}
        <div className="row ">
          {offers
            ? offers.map((item) => {
                // console.log(item, "aaaaaaaaa");
                return <OfferzoneCard item={item} key={item?.id} />;
              })
            : ""}
          {/* Repeat the card structure 4 more times */}
        </div>
        {/* {Data?.products?.map((prod,i)=>{ */}
      </div>
    </div>

    // </div>
  );
};

export default OfferZone;
